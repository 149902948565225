<template>
    <div style="background-color: #f2f2f2;">
        <div style="border: 1px solid red;  height: 100vh; display: flex; justify-content: center; align-items: center;">
            
            <div style="width: 90%; height: 84%;  border-radius: 1rem; background-color: #A4D7C7; position: relative; overflow: hidden;">
                <div style=" height: 90%; aspect-ratio: 0.8; position: absolute; top: 3%; left: 34%; background-color: #FCFCFC;box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" height: 50%; aspect-ratio: 0.8; position: absolute; top: 14%; left: 10%; background-color: #FCFCFC;box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" height: 30%; aspect-ratio:0.8; position: absolute; top: -5%; right: 10%; background-color: #FCFCFC;box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" height: 60%; aspect-ratio: 0.8; position: absolute; top: 20%; right: -10%; background-color: #FCFCFC;box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" width: 40%; aspect-ratio: 1.5; position: absolute; bottom: -30%; right:5%; background-color: #FCFCFC;box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" width: 40%; aspect-ratio: 1.5; position: absolute; bottom: -10%; left:-25%; background-color: #FCFCFC;box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);"></div>
            </div>
        </div>
        <div style="border: 1px solid blue; height: 100vh;">
            <div >
                projects i have worked on
            </div>
            <div>
                111
            </div>
        </div>
        <div >
            <div >
                my partners
            </div>
            <div >
                111
            </div>
        </div>
        <div >
            <div >
                mylife
            </div>
            <div >
                111
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        name:'JCenter',
        data(){
            return{

            }
        },
        methods:{
            drawCertificatesCanvas(){
                const canvas = this.$refs.certificates_canvas
                const ctx = canvas.getContext('2d')
                ctx.beginPath()
                ctx.moveTo(0, 0)
                ctx.lineTo(700, 0)
                ctx.lineTo(700, 400)
                ctx.lineTo(0, 500)
                ctx.closePath()
                ctx.fillStyle = '#7B9D7C'
                ctx.fill()
                ctx.strokeStyle = '#000000'
                ctx.lineWidth = 2
                ctx.stroke()
            }
        },
        mounted(){
            this.drawCertificatesCanvas()
        }
    }
</script>
<style scoped>

</style>