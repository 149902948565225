<template>
  <div id="app">
    <JHeader style="width: 100%; height: 6vh; position: fixed; z-index: 1000;"></JHeader>
    <JCenter ></JCenter>
    <JFooter></JFooter>
  </div>
</template>

<script>
import JHeader from './components/JHeader.vue'
import JFooter from './components/JFooter.vue'
import JCenter from './components/JCenter.vue'
export default {
  name: 'App',
  components: {
    JHeader,
    JCenter,
    JFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
