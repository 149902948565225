<template>
  <div style="display: flex; justify-content: space-between; align-items: center; height: 6vh; ">
    <div style="display: flex; align-items: center; width: 20%; height: 100%;margin-left: 2%; ">
        <img src="@/assets/J.png" style="height: 60%; "/>
        <span style="font-size: 1.3rem;">Jieduishuai</span>
    </div>
    <div style=" display: flex; margin-right: 2%; align-items: center; height: 100%; width: 10%;">
        <span style=" margin-right: 6%; font-family: Arial, Helvetica, sans-serif;">Login</span>
        <div style="background-color: #A4D7C7; height: 40%; width: 40%;   border-radius: 1.2rem; color: white; display: flex; justify-content: center; align-items: center; ">Sign up</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JHeader'
}
</script>

<style>

</style>